import React from 'react'
import Works from './Works'

export default function Korean() {
    const img = {
        //width: '100vw',
        //backgroundColor: 'bisque'
    }

    return (
        <div style={img}>
            <Works/>
        </div>
    )
}