import React from 'react'

export default function Japanese() {
    const img = {
        width: '100vw',
        height: '100vh',
        backgroundColor: 'thistle'
    }

    return (
        <div style={img}></div>
    )
}